
import { Col, Container, Input, Label, Row } from "reactstrap";
import React, { useEffect, useState } from "react";
import { findDataRequest, getAgeCategoryListRequest, getDataListRequest, getEventRequest, getRaceListRequest } from "../../api/controller/ExtController";
import Select from "react-select";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { Loader } from "../../custom/components/Loader";
// import { debounce } from 'lodash';
import { Table as ReactTable } from 'reactstrap';
import moment from "moment";

const Timing = () => {

    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    const [tabLoading, setTabLoading] = useState(false);
    const [preloader, setPreloader] = useState(true);
    const [loading, setLoading] = useState(false);

    const [event, setEvent] = useState(null);
    const [races, setRaces] = useState([]);
    const [genders] = useState([{ label: "All", value: null }, { label: "Male", value: "Male" }, { label: "Female", value: "Female" }]);
    const [ageCategories, setAgeCategories] = useState([{ label: "All", value: null }]);

    const [selectedRace, setSelectedRace] = useState('');
    const [selectedGender, setSelectedGender] = useState({ label: "All", value: null });
    const [selectedAgeCategory, setSelectedAgeCategory] = useState({ label: "All", value: null });

    const [search, setSearch] = useState("");

    const [activeTab, setActiveTab] = useState("results");
    const [selectedPersonData, setSelectedPersonData] = useState(null);

    // eslint-disable-next-line
    const [refreshDisabled, setRefreshDisabled] = useState(false);

    const [refreshTimer, setRefreshTimer] = useState(null);

    const [timeTrigger, setTimeTrigger] = useState(null);

    useEffect(() => {
        document.title = "Results - irunners Events"
        async function fetchData(){
            const tempEvent = await getEventRequest({});
            const raceList = await getRaceListRequest({});
            setRaces(raceList?.data?.map(i=>({label: i?.name, value: i?.id})));
            setEvent(tempEvent?.data);
            if(selectedRace?.value){
                const ageCategoryList = await getAgeCategoryListRequest({ Race: selectedRace?.value });
                setAgeCategories([{ label: "All", value: null }, ...ageCategoryList?.data?.map(i=>({label: i?.name, value: i?.id}))]);
            }
            setPreloader(false)
        }
        fetchData()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        async function fetchData(){
            const ageCategoryList = await getAgeCategoryListRequest({ Race: selectedRace?.value });
            if(ageCategoryList?.data?.length > 0){
                setAgeCategories([{ label: "All", value: null }, ...ageCategoryList?.data?.map(i=>({label: i?.name, value: i?.id}))]);
            }
        }
        if(selectedRace?.value){
            fetchData()
        }
    }, [selectedRace])

    
    async function fetchTimingData(postData, silent=false){
        if(! silent){
            setLoading(true);
            setData([])
        }
        setRefreshDisabled(true)
        const dataList = await getDataListRequest(postData);
        setData(dataList?.data);
        setTimeout(()=>setRefreshDisabled(false), 5000)
        if(refreshTimer){
            clearTimeout(refreshTimer)
        }
        setRefreshTimer(setTimeout(()=>setTimeTrigger(moment().format("H:i:s")), 120000))
        setLoading(false);
    }

    useEffect(() => {
        if(selectedRace?.value){
            fetchTimingData({ Race: selectedRace?.value, Gender: selectedGender?.value, AgeCategory: selectedAgeCategory?.value })
        }
        // eslint-disable-next-line
    }, [selectedRace, selectedGender, selectedAgeCategory])

    useEffect(() => {
        if(selectedRace?.value){
            fetchTimingData({ Race: selectedRace?.value, Gender: selectedGender?.value, AgeCategory: selectedAgeCategory?.value }, true)
        }
        // eslint-disable-next-line
    }, [timeTrigger])

    useEffect(()=>{
        if(search === ""){
            setFilteredData(data)
        } else {
            setFilteredData(data?.filter(i=>i?.name.toLowerCase().includes(search.toLowerCase()) || i?.bib_number.toLowerCase().includes(search.toLowerCase())))
        }
        // eslint-disable-next-line
    }, [search, data])

    // eslint-disable-next-line
    // const delayedSearch = useCallback(debounce((postData) => fetchTimingData(postData), 500), []);

    const toggleFullscreen = () => {
        if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement) {
          // current working methods
          if (document.documentElement.requestFullscreen) {
            document.documentElement.requestFullscreen();
          } else if (document.documentElement.mozRequestFullScreen) {
            document.documentElement.mozRequestFullScreen();
          } else if (document.documentElement.webkitRequestFullscreen) {
            document.documentElement.webkitRequestFullscreen(
                Element.ALLOW_KEYBOARD_INPUT
            );
          }
        } else {
          if (document.cancelFullScreen) {
            document.cancelFullScreen();
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
          } else if (document.webkitCancelFullScreen) {
            document.webkitCancelFullScreen();
          }
        }
    }

    useEffect(() => {
        console.log(event)
    }, [event])

    return (
        <React.Fragment>
            {preloader ? (
                <div id="preloader">
                    <div id="status">
                    <div className="spinner-chase">
                        <div className="chase-dot"></div>
                        <div className="chase-dot"></div>
                        <div className="chase-dot"></div>
                        <div className="chase-dot"></div>
                        <div className="chase-dot"></div>
                        <div className="chase-dot"></div>
                    </div>
                    </div>
                </div>
            ) : (
                <React.Fragment>
                    {event?.timing_cover_image && <img src={process.env.REACT_APP_API_PUBLIC_URL + event?.timing_cover_image} alt="cover" className="cover-image" width={"100%"} />}
                    <Container style={{minHeight:"500px", backgroundColor: 'white'}}>
                        {/* <h3 align="center" className="pt-5 mb-3">irunners Events Results</h3> */}
                        <div className="p-2" style={{position: 'relative'}}>
                            <Loader loading={tabLoading} />
                            {activeTab === "results" && <div className="results-tab">
                                <Row>
                                    <Col xs={12} md={4} className="mt-2">
                                        <Label>Race</Label>
                                        <Select classNamePrefix="select2-selection" options={races} value={selectedRace} onChange={(e)=>{
                                            setSelectedRace(e);
                                            setSelectedAgeCategory(null);
                                        }} />
                                    </Col>
                                    <Col xs={12} md={4} className="mt-2">
                                        <Label>Gender</Label>
                                        <Select classNamePrefix="select2-selection" options={genders} value={selectedGender} onChange={(e)=>setSelectedGender(e)} />
                                    </Col>
                                    <Col xs={12} md={4} className="mt-2">
                                        <Label>Age Category</Label>
                                        <Select classNamePrefix="select2-selection" options={ageCategories} value={selectedAgeCategory} onChange={(e)=>setSelectedAgeCategory(e)} />
                                    </Col>
                                    <Col xs={9} className="mt-2">
                                        <Label>Search</Label>
                                        <Input type="text" value={search} placeholder="Search by Name / Bib Number..." onChange={(e)=>{
                                            setSearch(e.target.value)
                                            // delayedSearch({ Race: selectedRace?.value, Gender: selectedGender?.value, AgeCategory: selectedAgeCategory?.value, SearchQuery: e.target.value })
                                        }} />
                                    </Col>
                                    <Col xs={3} className="d-flex align-items-center mt-2">
                                        <span className="results-icon mt-4" onClick={()=>toggleFullscreen()}><i className="mdi mdi-fullscreen"></i></span>&nbsp;&nbsp;
                                        {/* <span className={("results-icon mt-4 "+(refreshDisabled ? "text-muted" : ""))} style={refreshDisabled ? { cursor: 'default' } : { cursor: 'pointer' }} onClick={()=>{
                                            if(!refreshDisabled && selectedRace?.value){
                                                fetchTimingData({ Race: selectedRace?.value, Gender: selectedGender?.value, AgeCategory: selectedAgeCategory?.value, SearchQuery: search })
                                            }
                                        }}><i className="mdi mdi-refresh"></i></span> */}
                                    </Col>
                                </Row>
                                <Row style={{position: 'relative'}}>
                                    <Loader loading={loading} />
                                    <div className="table-rep-plugin">
                                        <div className="table-responsive mb-0 d-none d-md-block" data-pattern="priority-columns">
                                            {filteredData?.length === 0 && <h3 className="text-center text-muted mt-5">{selectedRace?.value ? "- No Results Found -" : "- Please Select a Race -"}</h3>}
                                            {filteredData?.length > 0 ? (
                                                <div>
                                                    <Table id="results" className="table table-striped mt-3">
                                                        <Thead>
                                                            <Tr style={{backgroundColor: "rgba(0,0,0,0.4)", color: "white"}}>
                                                                <Th style={{textAlign:"center"}}>Pos.</Th>
                                                                <Th>Name</Th>
                                                                <Th style={{textAlign:"center"}}>Bib Number</Th>
                                                                <Th style={{textAlign:"center"}}>Time</Th>
                                                                <Th style={{textAlign:"center"}}>AC</Th>
                                                                <Th style={{textAlign:"center"}}>AC Pos.</Th>
                                                                <Th style={{textAlign:"center"}}>Status</Th>
                                                            </Tr>
                                                        </Thead>
                                                        <Tbody>
                                                            {filteredData?.map((i,k)=>(
                                                                <Tr key={k} style={{backgroundColor: 'rgba(0,0,0,0.05)'}}>
                                                                    <Td align="center">{(selectedGender?.value ? i?.gender_place : i?.overall_place) || "-"}</Td>
                                                                    <Td><a className="results-name" href="/" onClick={async(e)=>{
                                                                        e.preventDefault();
                                                                        setActiveTab("details")
                                                                        setTabLoading(true)
                                                                        const temp = await findDataRequest({ id: i?.id })
                                                                        setSelectedPersonData(temp?.data)
                                                                        setTabLoading(false)
                                                                    }}>{i?.name + (i?.nationality ? ` (${i?.nationality})` : "")}</a></Td>
                                                                    <Td align="center">{i?.bib_number}</Td>
                                                                    <Td align="center"><b>{i?.chip_time_f || "-"}</b></Td>
                                                                    <Td align="center">{i?.age_category_name || "-"}</Td>
                                                                    <Td align="center">{i?.age_category_place || "-"}</Td>
                                                                    <Td align="center">{i?.timing_status || "-"}</Td>
                                                                </Tr>
                                                            ))}
                                                        </Tbody>
                                                    </Table>
                                                    {/* <h5 className="text-center text-primary" style={{cursor:'pointer'}} onClick={()=>window.scrollTo(0, 0)}>Filter to see more results</h5> */}
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="d-block d-md-none mt-3">
                                            <hr />
                                            {filteredData?.length === 0 && <div className="results-card mb-2 p-2"><Row className="mb-2"><Col xs={12}><h3 className="text-center text-muted">- No Results Found -</h3></Col></Row></div>}
                                            {filteredData?.map((i,k)=>(
                                                <div key={k} className="results-card mb-2 p-2" style={{backgroundColor: k%2===0 ? "" : "rgba(0,0,0,0.07)"}}>
                                                    <Row className="mb-2">
                                                        <Col xs={2}>
                                                            <b style={{fontSize:'large'}}>{(selectedGender?.value ? i?.gender_place : i?.overall_place) || "-"}</b>
                                                        </Col>
                                                        <Col xs={10} className="details">
                                                            <a className="results-name" href="/" style={{fontSize: 'large'}} onClick={async(e)=>{
                                                                e.preventDefault();
                                                                setActiveTab("details")
                                                                setTabLoading(true)
                                                                const temp = await findDataRequest({ id: i?.id })
                                                                setSelectedPersonData(temp?.data)
                                                                setTabLoading(false)
                                                            }}>{i?.name + (i?.nationality ? ` (${i?.nationality})` : "")}</a>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={2}></Col><Col xs={3}><span className="text-muted">BIB No.</span></Col><Col xs={4}><span className="text-muted">AC</span></Col><Col xs={3}><span className="text-muted">AC Pos.</span></Col>
                                                    </Row>
                                                    <Row className={"mb-1"}>
                                                        <Col xs={2}></Col>
                                                        <Col xs={3}><span style={{fontWeight:500}}>{i?.bib_number || "-"}</span></Col>
                                                        <Col xs={4}><span style={{fontWeight:500}}>{i?.age_category_name || "-"}</span></Col>
                                                        <Col xs={3}><span style={{fontWeight:700}}>{i?.age_category_place || "-"}</span></Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={5}></Col><Col xs={4}><span className="text-muted">Status</span></Col><Col xs={3}><span className="text-muted">Time</span></Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={5}></Col>
                                                        <Col xs={4}><span style={{fontWeight:500}}>{i?.timing_status || "-"}</span></Col>
                                                        <Col xs={3}><span style={{fontWeight:700}}>{i?.chip_time_f || "-"}</span></Col>
                                                    </Row>
                                                </div>
                                            ))}
                                            {/* <div className="results-card mb-2 p-2"><Row className="mb-2"><Col xs={12}><h5 className="text-center text-primary" onClick={()=>window.scrollTo(0, 0)}>Filter to see more results</h5></Col></Row></div> */}
                                        </div>
                                    </div>
                                </Row>
                            </div>}
                            {activeTab === "details" && <div className="details-tab">
                                <button className="btn btn-secondary" onClick={()=>{
                                    setActiveTab("results")
                                    setSelectedPersonData(null)
                                }}><i className="mdi mdi-chevron-left"></i> Back</button>
                                <h3 className="mt-3">{selectedPersonData?.name + (selectedPersonData?.nationality ? ` (${selectedPersonData?.nationality})` : "")}</h3>
                                <hr/>
                                <Row>
                                    <Col xs={12} md={6}>
                                        <h5>Details</h5>
                                        <hr/>
                                        <ReactTable striped bordered hover>
                                            <colgroup>
                                                <col style={{ width: '50%' }} />
                                                <col style={{ width: '50%' }} />
                                            </colgroup>
                                            <tbody>
                                                <tr>
                                                    <td><b>Club</b></td>
                                                    <td>{selectedPersonData?.club || "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Age Category</b></td>
                                                    <td>{selectedPersonData?.age_category_name || "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Bib Number</b></td>
                                                    <td>{selectedPersonData?.bib_number || "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Start Time (Chip)</b></td>
                                                    <td>{selectedPersonData?.chip_start_time_f || "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Start Time (Gun)</b></td>
                                                    <td>{selectedPersonData?.race?.gun_time ? moment(selectedPersonData?.race?.gun_time).format("HH:mm:ss") : "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Status</b></td>
                                                    <td>{selectedPersonData?.timing_status || "-"}</td>
                                                </tr>
                                            </tbody>
                                        </ReactTable>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <h5>Totals</h5>
                                        <hr/>
                                        <ReactTable striped bordered hover>
                                            <colgroup>
                                                <col style={{ width: '50%' }} />
                                                <col style={{ width: '50%' }} />
                                            </colgroup>
                                            <tbody>
                                                <tr>
                                                    <td><b>Total Time (Chip)</b></td>
                                                    <td>{selectedPersonData?.chip_time_f || "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Total Time (Gun)</b></td>
                                                    <td>{selectedPersonData?.gun_time || "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Pos.</b></td>
                                                    <td>{(selectedGender?.value ? selectedPersonData?.gender_place : selectedPersonData?.overall_place) || "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Age Category Pos.</b></td>
                                                    <td>{selectedPersonData?.age_category_place || "-"}</td>
                                                </tr>
                                                {selectedPersonData?.certificate ? (
                                                    <tr>
                                                        <td><b>Certificate</b></td>
                                                        <td> <a href={process.env.REACT_APP_API_PUBLIC_URL+selectedPersonData?.certificate} target="_blank" rel="noreferrer" download><span className="mdi mdi-download"></span> Download</a></td>
                                                    </tr>
                                                ) : null}
                                            </tbody>
                                        </ReactTable>
                                    </Col>
                                </Row>
                            </div>}
                        </div>
                    </Container>
                </React.Fragment>
            )}
            
        </React.Fragment>
        
    )
    
}

export default Timing;

